import { Badge, Button, Divider, Flex, LoadingOverlay, Modal, Text } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { categoryApi } from "../../apis";
import { ICategory } from "../../interfaces/ICategory";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { PageLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import { SubClassificationForm } from "./sub-classification-form";

export function SubClassificationList() {
  const [action, setAction] = React.useState<string[]>([]);
  const ref = React.useRef<ITableRef>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setAction(["update", item as any]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  let res = await categoryApi.remove(item.id);
                  if (res.success) {
                    setTimeout(() => {
                      ref.current?.reload();
                    }, 200);
                  }
                  Message.success("Амжилттай дэд ангиллыг устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  Message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = () => {
    ref.current?.reload();
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Дэд ангилал"
        subTitle="Бараа бүтээгдэхүүн, ажил үйлчилгээний дэд ангилалийн жагсаалт"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference/product-list")}>
            Буцах
          </Button>,
          <Button
            key={2}
            onClick={() => {
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Divider my={10} />
        <Table
          ref={ref}
          name="sub-classificaiton.list"
          columns={columns}
          filters={{ query: "", type: "SUB_CLASSIFICATION" }}
          loadData={(data) => categoryApi.list(data!)}
        />
      </PageLayout>
      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} centered size="md">
        <SubClassificationForm onCancel={onCancel} action={action} reload={reload} />
      </Modal>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: ICategory) => void }): ColumnType<ICategory>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Код",
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.refCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр төрөл",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.itemType?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Ангилал",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.classification?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.description ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нээлттэй эсэх",
    dataIndex: "isPublic",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isPublic ? "green" : "red"}>
          {record.isPublic ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Бараа эсэх",
    dataIndex: "isGoods",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isGoods ? "green" : "red"}>
          {record.isGoods ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үйлчилгээ эсэх",
    dataIndex: "isService",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isService ? "green" : "red"}>
          {record.isService ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },

  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
            <IconEdit />
          </Button>
          <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
            <IconTrash />
          </Button>
        </Flex>
      );
    },
  },
];
