import { Box, Button, Grid, Group, Image, LoadingOverlay, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import Background from "../../assets/background-blur.svg";
import { Form } from "../../components/form";
import { TextField } from "../../components/form/text-field";
import Layout from "../../components/home/layout";
import { Message } from "../../utils/message";

const schema = yup.object({
  email: yup.string().email("Email байна").required("Хэрэглэгчийн нэрээ оруулна уу").trim().nullable(),
  username: yup.string().required("Хэрэглэгчийн нэрээ оруулна уу").trim(),
});

type IForm = {
  email: undefined | string;
  username: undefined | string;
};

export default function Forgot() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isSent, setIsSent] = React.useState<boolean>(false);

  const [data] = React.useState<IForm>({
    email: undefined,
    username: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      let res = await AuthApi.forgot(values);

      if (res.message) Message.success(res.message);
      setIsSent(true);
      setLoading(false);
    } catch (error: any) {
      Message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Image className={classes.background} src={Background} />
      <div className={classes.container}>
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
            <div className={classes.row}>
              <div>
                <Text fz={"lg"} c={"white"}>
                  Dehub.MN платформын Back Office
                </Text>
                <Text className={classes.text}>Тавтай морилно уу!</Text>
                <div className={classes.description}>
                  DeHUB платформд бүртгэлтэй компаниудын бүртгэл, хяналт, гэрээний харилцаа болон админ эрхийн тохиргоо хийх, платформ дээр ашиглагдах лавлах
                  мэдээллүүдийг бүртгэх, тохируулах, дотоод хэрэглэгчдийн бүртгэл, эрхийн тохиргоо
                </div>
              </div>
            </div>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
            <Paper withBorder className={classes.card}>
              <LoadingOverlay visible={loading} />
              <Text fz={40} c={"white"} w={800}>
                НУУЦ ҮГ СЭРГЭЭХ
              </Text>

              {isSent ? (
                <Box mt={50}>
                  <Text ta="center" c="white" fw={500} fz={20}>
                    Таны бүртгэлтэй email хаяг руу нууц үг сэргээх холбоос илгээсэн.
                  </Text>
                  <Button component="a" href="/auth/login" gradient={{ from: "purple", to: "blue" }} mt={100} variant="gradient" size="md" w="100%">
                    Нэвтрэх хуудасруу буцах
                  </Button>
                </Box>
              ) : (
                <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
                  {() => {
                    return (
                      <Grid mt={20} gutter={20}>
                        <Grid.Col h={100} span={12}>
                          <TextField
                            label="Email оруулна уу:"
                            variant="unstyled"
                            className={classes.input}
                            size="md"
                            name="email"
                            placeholder="ba***@mail.com..."
                          />
                        </Grid.Col>
                        <Grid.Col h={100} span={12}>
                          <TextField
                            label="Нэвтрэх нэр оруулна уу:"
                            variant="unstyled"
                            className={classes.input}
                            size="md"
                            name="username"
                            placeholder="Нэвтрэх нэр оруулна уу"
                          />
                        </Grid.Col>

                        <Grid.Col mt={20} span={12}>
                          <Group p="center">
                            <Text c="white">
                              Бүртгэлтэй бол
                              <Link to={"/auth/login"}>
                                <Text span c="blue" inherit fw={700} px={5}>
                                  Нэвтрэх
                                </Text>
                              </Link>
                            </Text>
                          </Group>
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <Button variant="gradient" gradient={{ from: "blue", to: "purple" }} type="submit" size="md" w="100%">
                            Илгээх
                          </Button>
                        </Grid.Col>

                        <Grid.Col mt={50} span={12}>
                          <Group p="center">
                            <Text c="white">Баталгаажуулалт хийх заавартай танилцах</Text>
                          </Group>
                        </Grid.Col>
                      </Grid>
                    );
                  }}
                </Form>
              )}
            </Paper>
          </Grid.Col>
        </Grid>
      </div>
    </Layout>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 80,
    paddingBottom: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  background: {
    position: "absolute",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
    zIndex: -1,
  },
  description: {
    marginTop: 8,
    maxWidth: "1000px",
    fontSize: 14,
    color: "white",
  },
  text: {
    marginTop: -5,
    fontSize: 64,
    fontWeight: 800,
    color: "white",
  },
  card: {
    backgroundColor: "#ffffff25",
    cursor: "pointer",
    borderRadius: "0.5rem",
    padding: "2rem 2.5rem",
    border: 0,
    maxWidth: 500,
    margin: "0 auto",
    [`@media screen and (max-width: 500px)`]: {
      maxWidth: "100%",
    },
  },

  input: {
    input: {
      borderRadius: 4,
      backgroundColor: "#E8F0Fe",
      paddingInline: 10,
    },
    label: {
      color: theme.white,
    },
  },
  passwordInput: {
    input: {
      borderRadius: 4,
      backgroundColor: "#E8F0Fe",
      paddingInline: 10,
    },
    label: {
      color: theme.white,
    },
    borderRadius: 4,
    backgroundColor: "#E8F0Fe",
    height: 42,
  },
}));
