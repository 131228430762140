import { Badge, Button, Flex, Grid, Group, Title } from "@mantine/core";
import { IconCheck, IconEdit, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import React, { useImperativeHandle } from "react";
import * as yup from "yup";
import { IField } from "../../interfaces/IField";
import { IFieldType } from "../../models/General";
import { numberRegex } from "../../utils";
import { Dialog } from "../../utils/confirm-modal";
import { Form, IFormRef } from "../form";
import { MultiCreateableField } from "../form/multi-createable-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { ColumnType, Table } from "../table";

const schema = yup.object({
  fields: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required("Заавал бөглөнө!").nullable(),
        type: yup.string().required("Заавал бөглөнө!").nullable(),
        defaultValueId: yup.string().optional().nullable(),
        numberUnit: yup.string().matches(numberRegex, "Тоон утга оруулна!").optional().nullable(),
        sort: yup.number().required("Заавал бөглөнө!").nullable(),
      }),
    )
    .optional(),
});

type Props = {
  onCancel?: () => void;
};

export type IDynamicRef = {
  submit: () => Promise<{
    fields: {
      name: string | undefined;
      type: IFieldType;
      numberUnit: string | undefined;
      defaultValueId: string | undefined;
      sort: number;
      values: string[];
      text?: string;
      number?: number;
      checked?: boolean;
    }[];
  }>;
};

const data = [
  {
    name: "SELECT",
  },
  {
    name: "NUMBER",
  },
  {
    name: "TEXT",
  },
  {
    name: "CHECKBOX",
  },
];

export const DynamicFieldForm = React.forwardRef(({ onCancel }: Props, ref: React.Ref<IDynamicRef>) => {
  const formRef = React.useRef<IFormRef<{ fields: IField[] }>>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const [data, setFormData] = React.useState<{ fields: IField[] }>({
    fields: [],
  });

  const columns = useHeader({
    action: action,
    fields: data.fields,
    onCreate: (option, index) => {
      const fields = (data?.fields || [])?.reduce((accumulator, iterator, i) => {
        if (i === index) {
          return [
            ...accumulator,
            {
              ...iterator,
              values: [
                ...(iterator.values || []),
                {
                  name: option.label,
                  id: option.value,
                  isDefault: false,
                },
              ],
            },
          ];
        }

        return [...accumulator, iterator];
      }, [] as IField[]);

      setFormData({
        fields: fields,
      });
    },
    onClick: async (key, record, index) => {
      switch (key) {
        case "cancel": {
          const errors = await formRef.current?.validate();

          if (errors && Object.keys(errors).length > 0) {
            formRef.current?.setFormData((state) => ({
              fields: state?.fields?.filter((_i, i) => i !== index),
            }));

            setAction([]);
          } else setAction([]);

          break;
        }
        case "edit": {
          setAction(["edit", `${index}`]);
          break;
        }
        case "save": {
          const errors = await formRef.current?.validate();

          if (errors && Object.keys(errors).length === 0) {
            setAction([]);
          }

          break;
        }
        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", (key) => {
            switch (key) {
              case "confirm": {
                formRef.current?.setFormData((state) => ({
                  fields: state?.fields?.filter((_i, i) => i !== index),
                }));
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  useImperativeHandle(ref, () => ({
    submit() {
      return formRef.current?.submit();
    },
  }));

  return (
    <Form ref={formRef} validationSchema={schema} initialValues={data}>
      {({ values }) => {
        return (
          <>
            <Flex direction="row" justify="space-between" align="center" mb="md">
              <Title size="md" c="dark.3">
                Динамик талбарууд
              </Title>
              <Button
                variant="light"
                size="xs"
                onClick={() => {
                  if (!action[0]) {
                    formRef.current?.setFormData((state) => {
                      setAction(["edit", `${state?.fields?.length || 0}`]);

                      const field: IField = {
                        name: undefined,
                        type: "TEXT",
                        numberUnit: undefined,
                        defaultValueId: undefined,
                        sort: 1,
                        values: [],
                      };

                      setFormData((state) => ({
                        ...state,
                        fields: [...state.fields, field],
                      }));

                      return {
                        fields: [...(state?.fields || []), field],
                      };
                    });
                  }
                }}
                disabled={action[0] === "edit"}>
                <IconPlus size={20} /> Шинэ талбар
              </Button>
            </Flex>
            <Grid>
              <Grid.Col span={12}>
                <Table name="sub.category.list.fields" columns={columns} dataSource={values.fields} pagination={false} />
              </Grid.Col>
            </Grid>
          </>
        );
      }}
    </Form>
  );
});

const useHeader = ({
  action,
  fields,
  onCreate,
  onClick,
}: {
  onClick: (key: string, record: IField, index: number) => void;
  action: string[];
  fields: IField[];
  onCreate: (option: { label: string; value: string }, index: number) => void;
}): ColumnType<IField>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Динамик талбарын нэр",
    dataIndex: "name",
    width: 240,
    render: (record, index) => {
      if (action[1] === `${index}`) return <TextField name={`fields[${index}].name`} placeholder="Динамик талбарын нэр" noError />;

      return record.name || "-";
    },
  },
  {
    title: "Өгөгдлийн төрөл",
    dataIndex: "type",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <SelectField
            name={`fields[${index}].type`}
            placeholder="Өгөгдлийн төрөл"
            noError
            options={(data || []).map((f) => ({
              label: f.name,
              value: f.name,
            }))}
          />
        );

      return record.type;
    },
  },
  {
    title: "Сонголтод утга",
    dataIndex: "values",
    render: (record, index) => {
      if (record.type !== "SELECT") return "-";

      if (action[1] === `${index}`)
        return (
          <MultiCreateableField
            name={`fields[${index}].values`}
            onCreate={(o) => onCreate(o, index)}
            placeholder="Сонголтод утга"
            options={(fields[index]?.values || []).map((v: any) => ({ label: v.name, value: v.id }))}
            noError
          />
        );

      return (
        <>
          {fields[index]?.values?.map((value: any, index: any) => (
            <Badge key={index} variant="dot" mr="xs">
              {value.name}
            </Badge>
          ))}
        </>
      );
    },
  },
  {
    title: "[Default] утга",
    dataIndex: "defaultValue",
    width: 200,
    render: (record, index) => {
      if (record.type === "SELECT" && action[1] === `${index}`)
        return (
          <SelectField
            name={`fields[${index}].defaultValueId`}
            placeholder="[Default] утга"
            options={(fields[index]?.values || []).map((v: any) => ({ label: v.name, value: v.id }))}
          />
        );

      return fields[index]?.values?.find((v: any) => v.id === record.defaultValueId || v.isDefault)?.name || "-";
    },
  },
  {
    title: "Нэгж",
    dataIndex: "status",
    width: 120,
    render: (record, index) => {
      if (record.type === "NUMBER" && action[1] === `${index}`) return <TextField name={`fields[${index}].numberUnit`} placeholder="Нэгж" />;

      return record.numberUnit || "-";
    },
  },
  {
    title: "Эрэмбэ",
    dataIndex: "sort",
    width: 120,
    render: (record, index) => {
      if (action[1] === `${index}`) return <NumberField name={`fields[${index}].sort`} placeholder="Эрэмбэ" noError hideControls min={0} max={1000} />;

      return `${record.sort}`;
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      if (action[0] === "edit" && action[1] === `${index}`)
        return (
          <Group>
            <Button variant="filled" radius={100} w={35} h={35} p={0} onClick={() => onClick("save", record, index)}>
              <IconCheck />
            </Button>

            <Button variant="default" radius={100} w={35} h={35} p={0} onClick={() => onClick("cancel", record, index)}>
              <IconX />
            </Button>
          </Group>
        );

      return (
        <Group>
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record, index)}>
            <IconEdit />
          </Button>
          <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record, index)}>
            <IconTrash />
          </Button>
        </Group>
      );
    },
    width: "120px",
  },
];
